import React, { useState } from "react";
import Header from "../shared/Header";
import Layout from "../shared/Layout";
import galimg from "../images/galimg.jpg";
import { Section, Content, Col, Line, H3, H2, P } from "../styles/shared";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import Footer from "../shared/Footer";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { Helmet } from "react-helmet";

//#region beach imgs

import beach1 from "../images/beach/beach1.jpg";
import beach2 from "../images/beach/beach2.jpg";
import beach3 from "../images/beach/beach3.jpg";
import beach4 from "../images/beach/beach4.jpg";
import beach5 from "../images/beach/beach5.jpg";
import beach6 from "../images/beach/beach6.jpg";
import beach7 from "../images/beach/beach7.jpg";
import beach8 from "../images/beach/beach8.jpg";
import beach9 from "../images/beach/beach9.jpg";
import beach10 from "../images/beach/beach10.jpg";
import beach11 from "../images/beach/beach11.jpg";
import beach12 from "../images/beach/beach12.jpg";
import beach13 from "../images/beach/beach13.jpg";

import liv1 from "../images/liv/liv1.jpg";
import liv2 from "../images/liv/liv2.jpg";
import liv3 from "../images/liv/liv3.jpg";
import liv4 from "../images/liv/liv4.jpg";
import liv5 from "../images/liv/liv5.jpg";
import liv6 from "../images/liv/liv6.jpg";
import liv8 from "../images/liv/liv8.jpg";
import liv9 from "../images/liv/liv9.jpg";
import liv10 from "../images/liv/liv10.jpg";
import liv11 from "../images/liv/liv11.jpg";

import bed1 from "../images/bed/bed1.jpg";
import bed2 from "../images/bed/bed2.jpg";
import bed3 from "../images/bed/bed3.jpg";
import bed4 from "../images/bed/bed4.jpg";
import bed5 from "../images/bed/bed5.jpg";
import bed7 from "../images/bed/bed7.jpg";
import bed8 from "../images/bed/bed8.jpg";
import bed9 from "../images/bed/bed9.jpg";
import bed10 from "../images/bed/bed10.jpg";
import bed11 from "../images/bed/bed11.jpg";
import bed12 from "../images/bed/bed12.jpg";
import bed13 from "../images/bed/bed13.jpg";

import bat1 from "../images/bath/bat1.jpg";
import bat2 from "../images/bath/bat2.jpg";
import bat3 from "../images/bath/bat3.jpg";
import bat4 from "../images/bath/bat4.jpg";
import bat5 from "../images/bath/bat5.jpg";
import bat6 from "../images/bath/bat6.jpg";
import Menu from "../shared/Menu";
//#endregion

//#region BEACH
const beachImgs = [
  {
    original: beach1,
    thumbnail: beach1,
  },
  {
    original: beach2,
    thumbnail: beach2,
  },
  {
    original: beach3,
    thumbnail: beach3,
  },
  {
    original: beach4,
    thumbnail: beach4,
  },
  {
    original: beach5,
    thumbnail: beach5,
  },
  {
    original: beach6,
    thumbnail: beach6,
  },
  {
    original: beach7,
    thumbnail: beach7,
  },
  {
    original: beach8,
    thumbnail: beach8,
  },
  {
    original: beach9,
    thumbnail: beach9,
  },
  {
    original: beach10,
    thumbnail: beach10,
  },
  {
    original: beach11,
    thumbnail: beach11,
  },
  {
    original: beach12,
    thumbnail: beach12,
  },
  {
    original: beach13,
    thumbnail: beach13,
  },
];
const beachDesc = [
  {
    originalTitle: "BEACH PERSPECTIVE",
    description: "A slice of true paradise.",
  },
  {
    originalTitle: "GROUND FLOOR TERRACE",
    description:
      "The perfect shaded place to chill during Hvar's infinitely sunny afternoons.",
  },
  {
    originalTitle: "DISTANT PERSPECTIVE",
    description:
      'The land in Medvidina Bay is truly untouched, as nature intended. That means giant agave plants and unruly "beansprouts" cropping up behind the beach.',
  },
  {
    originalTitle: "FIRST FLOOR TERRACE",
    description:
      "Don't fancy the beach? We're not sure why you wouldn't, but in any case, here's the sun terrace. Also perfect for laying out your mat for sunrise yoga as the sun pops it head out from behind that hill.",
  },
  {
    originalTitle: "DISTANT PERSPECTIVE II",
    description:
      "Medvidina Bay has only a handful of houses... including Antun's 'konoba' that you can see here adjacent to the villa. ",
  },
  {
    originalTitle: "GROUND FLOOR TERRACE",
    description:
      "A perfect shady spot in the afternoons, and an awesome spot for dinner together in the evenings.",
  },
  {
    originalTitle: "FIRST FLOOR TERRACE",
    description:
      "Otherworldly, right? Note the outdoor staircase that leads to the upstairs bedroom and bathroom. Perfect for older kids if they all want to camp out upstairs together. Peace and quiet for the adults!",
  },
  {
    originalTitle: "WHERE DREAMS COME TRUE",
    description: "Quiet, serene, and magical.",
  },
  {
    originalTitle: "GROUND FLOOR TERRACE",
    description: "#nofilter – that's just the colour of the Adriatic. ",
  },
  {
    originalTitle: "THAT VIEW",
    description: "View of the pelješac from the first floor sun terrace.",
  },
  {
    originalTitle: "BBQ AREA",
    description:
      "A perfect spot to prepare fresh, delicious evening meals to enjoy on the sea view terrace.",
  },
  {
    originalTitle: "THAT VIEW",
    description: "Could you ever get bored of waking up to this view?",
  },
  {
    originalTitle: "PARKING TERRACE",
    description:
      "The terrace next to the house has space for at least two cars.",
  },
];
//#endregion

//#region LIV
const livImgs = [
  {
    original: liv1,
    thumbnail: liv1,
  },
  {
    original: liv2,
    thumbnail: liv2,
  },
  {
    original: liv3,
    thumbnail: liv3,
  },
  {
    original: liv4,
    thumbnail: liv4,
  },
  {
    original: liv5,
    thumbnail: liv5,
  },
  {
    original: liv6,
    thumbnail: liv6,
  },
  {
    original: liv8,
    thumbnail: liv8,
  },
  {
    original: liv9,
    thumbnail: liv9,
  },
  {
    original: liv10,
    thumbnail: liv10,
  },
  {
    original: liv11,
    thumbnail: liv11,
  },
];

const livDesc = [
  {
    originalTitle: "DINER / LIVING AREA",
    description: "Open plan living.",
  },
  {
    originalTitle: "LIVING AREA",
    description: "The cosy living room that looks out over the sea.",
  },
  {
    originalTitle: "KITCHEN DINER",
    description:
      "Kitchen diner with international library stocked up with some great holiday reads. ",
  },
  {
    originalTitle: "KITCHEN",
    description: "Fully equipped with everything you need.",
  },
  {
    originalTitle: "LIVING AREA",
    description:
      "This space was created for Netflix and board games together. ",
  },
  {
    originalTitle: "KITCHEN DINER",
    description:
      "Fully equipped, modern kitchen with dishwasher | 100% powered by ☀️",
  },
  {
    originalTitle: "LITTLE TOUCHES",
    description: "No detail left unattended.",
  },
  {
    originalTitle: "DELONGHI COFFEE MACHINE",
    description: "Because we believe quality matters. ",
  },
  {
    originalTitle: "YOUR COMFORT",
    description: "Is always our consideration.",
  },
  {
    originalTitle: "STYLISH",
    description: "Even when washing dishes.",
  },
];

//#endregion

//#region BED
const bedImgs = [
  {
    original: bed1,
    thumbnail: bed1,
  },
  {
    original: bed2,
    thumbnail: bed2,
  },
  {
    original: bed3,
    thumbnail: bed3,
  },
  {
    original: bed4,
    thumbnail: bed4,
  },
  {
    original: bed5,
    thumbnail: bed5,
  },
  {
    original: bed7,
    thumbnail: bed7,
  },
  {
    original: bed8,
    thumbnail: bed8,
  },
  {
    original: bed9,
    thumbnail: bed9,
  },
  {
    original: bed10,
    thumbnail: bed10,
  },
  {
    original: bed11,
    thumbnail: bed11,
  },
  {
    original: bed12,
    thumbnail: bed12,
  },
  {
    original: bed13,
    thumbnail: bed13,
  },
];
const bedDesc = [
  {
    originalTitle: "BEDROOM II",
    description: "First floor (with terrace patio door)",
  },
  {
    originalTitle: "BEDROOM I",
    description: "Ground floor.",
  },
  {
    originalTitle: "BEDROOM II",
    description: "First floor (with terrace patio door)",
  },
  {
    originalTitle: "BEDROOM I",
    description: "Ground floor.",
  },
  {
    originalTitle: "BEDROOM III",
    description: "First floor.",
  },
  {
    originalTitle: "BEDROOM IV",
    description: "Second floor (accessed via external staircase).",
  },
  {
    originalTitle: "BEDROOM IV",
    description: "Second floor (accessed via external staircase).",
  },
  {
    originalTitle: "BEDROOM IV",
    description: "Second floor.",
  },
  {
    originalTitle: "BEDROOM IV",
    description: "Second floor.",
  },
  {
    originalTitle: "BEDROOM IV",
    description: "Second floor.",
  },
  {
    originalTitle: "BEDROOM IV",
    description: "Second floor.",
  },
  {
    originalTitle: "BEDROOM IV",
    description: "Second floor.",
  },
];
//#endregion

//#region BATH
const batImgs = [
  {
    original: bat1,
    thumbnail: bat1,
  },
  {
    original: bat2,
    thumbnail: bat2,
  },
  {
    original: bat3,
    thumbnail: bat3,
  },
  {
    original: bat4,
    thumbnail: bat4,
  },
  {
    original: bat5,
    thumbnail: bat5,
  },
  {
    original: bat6,
    thumbnail: bat6,
  },
];
const batDesc = [
  {
    originalTitle: "BATHROOM I",
    description: "Ground floor.",
  },
  {
    originalTitle: "BATHROOM II",
    description:
      "First floor. Shampoo, conditioner, and body wash provided in each shower.",
  },
  {
    originalTitle: "BATHROOM II",
    description: "First floor.",
  },
  {
    originalTitle: "NATURAL HAND SOAPS",
    description: "Handmade, natural bar soaps are provided in each bathroom. ",
  },
  {
    originalTitle: "BATHROOM III",
    description:
      "Second floor (ensuite to Bedroom IV). Hand wash and lotion provided in each bathroom. ",
  },
  {
    originalTitle: "BATHROOM III",
    description: "Second floor (ensuite to Bedroom IV). ",
  },
];
//#endregion

export default function Gallery() {
  const [beach, setBeach] = useState(0);
  const [kit, setKit] = useState(0);
  const [bed, setBed] = useState(0);
  const [ind, setInd] = useState(0);
  const [open, setOpen] = useState(false);
  const breakpoints = useBreakpoint();
  return (
    <Layout>
      {!open ? (
        <>
          <Helmet>
            <title>Gallery - La Villa Bleue</title>
            <meta
              name="description"
              content="Explore the picturesque gallery of La Villa Bleue, an exquisite seaside retreat where Mediterranean beauty meets contemporary luxury."
            />
          </Helmet>
          <Header bg={galimg} type="short" setOpen={setOpen} />
          <Section>
            <Content>
              <Col align="start">
                <H3 align="left">
                  EXTERIOR <Line align="start" />
                </H3>
                <ImageGallery
                  items={beachImgs}
                  lazyLoad={true}
                  showBullets={false}
                  showNav={true}
                  showPlayButton={false}
                  autoPlay={true}
                  showThumbnails={false}
                  showFullscreenButton={breakpoints.sm ? false : true}
                  onSlide={(currentIndex) => {
                    setBeach(currentIndex);
                  }}
                />
                <H2 align="left">{[beachDesc[beach].originalTitle]}</H2>
                <P
                  align="left"
                  color="rgb(123,116,110)"
                  style={{ marginTop: "-15px" }}
                >
                  {[beachDesc[beach].description]}
                </P>
              </Col>
            </Content>
          </Section>
          <Section bg="rgb(238,236,234)">
            <Content>
              <Col align="end">
                <H3 align="right">
                  LIVING AREA KITCHEN <Line align="end" />
                </H3>
                <ImageGallery
                  items={livImgs}
                  lazyLoad={true}
                  showBullets={false}
                  showNav={true}
                  showPlayButton={false}
                  autoPlay={true}
                  showThumbnails={false}
                  showFullscreenButton={breakpoints.sm ? false : true}
                  onSlide={(currentIndex) => {
                    setKit(currentIndex);
                  }}
                />
                <H2 align="right">{[livDesc[kit].originalTitle]}</H2>
                <P
                  align="right"
                  color="rgb(123,116,110)"
                  style={{ marginTop: "-15px" }}
                >
                  {[livDesc[kit].description]}
                </P>
              </Col>
            </Content>
          </Section>
          <Section>
            <Content>
              <Col align="start">
                <H3 align="left">
                  BEDROOMS
                  <Line align="start" />
                </H3>
                <ImageGallery
                  items={bedImgs}
                  lazyLoad={true}
                  showBullets={false}
                  showNav={true}
                  showPlayButton={false}
                  autoPlay={true}
                  showThumbnails={false}
                  showFullscreenButton={breakpoints.sm ? false : true}
                  onSlide={(currentIndex) => {
                    setBed(currentIndex);
                  }}
                />
                <H2 align="left">{[bedDesc[bed].originalTitle]}</H2>
                <P
                  align="left"
                  color="rgb(123,116,110)"
                  style={{ marginTop: "-15px" }}
                >
                  {[bedDesc[bed].description]}
                </P>
              </Col>
            </Content>
          </Section>
          <Section bg="rgb(238,236,234)">
            <Content>
              <Col align="end">
                <H3 align="right">
                  BATHROOMS <Line align="end" />
                </H3>
                <ImageGallery
                  items={batImgs}
                  lazyLoad={true}
                  showBullets={false}
                  showNav={true}
                  showPlayButton={false}
                  autoPlay={true}
                  showThumbnails={false}
                  showFullscreenButton={breakpoints.sm ? false : true}
                  onSlide={(currentIndex) => {
                    setInd(currentIndex);
                  }}
                />
                <H2 align="right">{[batDesc[ind].originalTitle]}</H2>
                <P
                  align="right"
                  color="rgb(123,116,110)"
                  style={{ marginTop: "-15px" }}
                >
                  {[batDesc[ind].description]}
                </P>
              </Col>
            </Content>
          </Section>
          <Footer type="full" />
        </>
      ) : (
        <Menu setOpen={setOpen} />
      )}
    </Layout>
  );
}
